@tailwind base;
@tailwind components;
@tailwind utilities;

.container-wrapper {
    @apply max-w-[1280px] mx-auto w-[90%];
  }

*{
padding: 0;
margin: 0;
}
body{
    background-color: #232323;
}

.input-box{
    background: #f9f9fb00;
   
    box-sizing: border-box;
    
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    height: 46px;
}

.navbar{
    
    height: 100px;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
}

.gradent{
    background: linear-gradient(269.26deg,#347933b3 6.59%,#369967 94.04%);  
    border-radius: 8px 8px 0px 0px;
}

.footer{
   
    padding-top:1rem ;
    padding-bottom: 1rem;
    margin-top: 1rem;
}
.alchemy-faucet-background-1 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 52%;
   
    clip-path: polygon(0 0,100% 0,100% 76%,0 100%);
    z-index: -1;
}

.alchemy-faucet-background-2{
    position: absolute;
    top: 38%;
    width: 100%;
    height: 52%;
   
    z-index: -2;
}
.copy-btn{
    background: black;
    border: 1px solid green;
    border-radius: 12px;
    padding: 0.2rem 0.5rem;
}