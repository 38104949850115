pop-up-sign {
    position: absolute;
    top: calc(100% - 47.2rem);
  }
  .pop-up-sign2 {
    position: absolute;
    top: calc(100% - 50.2rem);
    left: -4%;
  }
  
  .modalBackground {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.233);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 1000;
  }
  
  .modalContainer {
    width: 660px;
    height: auto;
    background: #fff;
    backdrop-filter: blur(6.5px);
    border-image-slice: 1;
    border: 2px solid #3f1794;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding:12px 36px 25px;

    z-index: 2;
  }
  .btn-con{
    padding: 10px 20px 10px 20px;
    background: linear-gradient(90.22deg, #9006EE 0%, #4B24AB 100%);
border-radius: 100px;
  }

  
  .pre-step-1 h3 {
    font-size: 13px;
    color: #fff;
    padding-top: 5px;
  }
  
  .dee-bal {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 1rem;
  }
  .in-put-pre {
    background: #ffffff26;
    outline: none;
    border: 1px solid #000000;
    width: 100%;
    padding: 6px;
    height: 28px;
  }
  .dee-coin {
    padding-right: 4px;
  }
  .btn-max {
    background: #00e86c00;
    border: 1px solid #6ae57b;
    border-radius: 4px;
  
    padding: 0% 1%;
    font-size: 14px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    /* margin-right: 2%; */
  }
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  .pop-head h2 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 600;
  }
  .pop-pre-h h2 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
  }
  .pre-pop-btn {
    margin-top: 20px;
    margin-bottom: 5px;
    background-color: #00e86d;
    color: #000000;
    font-weight: 600;
    padding: 0.5% 6%;
    font-size: 18px;
  }
  .pop-head h1 {
    font-size: 16px;
  }
  .titleCloseBtn button {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }


  
 




  